header {
    padding-top: 20px !important;
}
.header_smart_solution {
    background-color: #FFF;
}
.logoHeader {
    display: none ;
}

.header_smart_solution img {
    display: block !important;
}

#header_smart a:link { text-decoration: none; }
#header_smart a { cursor: pointer; }

.header_smart_solution a  {
    color : black !important ; 
}


.header_smart_solution button  {
    color : black !important ; 
}
.header_smart_solution .a_btn  {
    color : rgb(255, 255, 255) !important ; 
}

.header_smart_solution a:hover {
    border-color: black !important;
}

.MuiDrawer-root {
    z-index: 30000 !important ; 
}

.sidebarCatList {
    width: 100%;
    overflow-x: hidden;
    padding : 10px !important;
}

.sidebarCatList span {
    font-size: 12px !important;
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    
}

.bg-image {
    background-size: cover !important;
}