
.cat_item_in_list{
    width : max-content ; height : 40px ; font-size : 15px ; cursor : pointer ;
     color: white;
     margin-left: 5px !important;
     display: inline-block;
     border-radius: 5px;
}

.cat_item_in_list.active_item , .cat_item_in_list:hover {
    color : black !important ; background : white !important ;
}

a {
    color : black !important ;
}
span.page-link {
    background: #344767 !important;
    color: white !important;
    border: none !important;
    cursor : pointer !important;  
}
a.page-link {
    background: white !important;
    color: black !important;
    border: none !important;
    cursor : pointer !important;  
}

.MuiPagination-root button {
     
      color: rgb(255, 255, 255) !important;
      font-weight: bold ;
   
  }
.MuiPagination-root button.Mui-selected {
    
color: rgb(7, 32, 33) !important;

}

  